/* 공통 스타일 */
.water-mark {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 0px;
    background: linear-gradient(135deg, #f8f9fa, #e9ecef);
    font-family: 'Arial', sans-serif;
    color: #333;
    background-image: url('../public/images/patternImage.png');  
  }
  
  .water-mark-header {
    text-align: center;
    margin-bottom: 20px;
    justify-content: flex-start; /* Align content at the top with spacing */
    padding-top: 0px; /* Add padding to push content below the top menu */
  }
  
  .water-mark-header h1 {
    font-size: 2.5rem;
    color: #0069d9;
  }
  
  .instruction-text {
    padding-top: 10px;
    font-size: 1.2rem;
    color: #555;
  }
  
  .image-preview {
    width: 300px;
    height: 200px;
    margin: 40px auto;
    border: 1px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #fff;
    background-image: url('../public/images/imageconvertpreview.png');  
  }
  
  .preview-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .output-image-container{ 
    width: 600px;
    height: auto;
    margin: 10px auto;
    border: 1px dashed #ccc;  
    overflow: hidden;
    background-color: #fff;
  }
  
  .output-image {
    max-width: 100%;
    max-height: 100%;
  }

  .placeholder {
    color: #888;
    font-size: 16px;
  }
  
  .controls {
    margin-top: 0px;
  }
  
  .upload-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--color-orange); /* Primary blue color */
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: var(--color-yellow); /* Darker blue for hover */
  }
  
  .upload-button:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(0, 105, 217, 0.5); /* Light blue focus glow */
  }
  
  .upload-button-hidden {
    display: none; /* To hide the actual input element */
  }
  
  
  .watermark-output-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 20px; */
    padding: 10px;
    background: #f8f9fa; /* 연한 배경색 */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 약간의 그림자 효과 */
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .format-label {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .format-dropdown {
    padding: 8px 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
  }
  
  .format-dropdown:hover {
    border-color: #0069d9; /* 파란색 강조 */
  }
  
  .save-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #269830; /* 녹색 */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 300px;
  }
  
  .save-button:hover {
    background-color: #1d7a24; /* 좀 더 짙은 녹색 */
  }
  
  /* Desktop 스타일 */
  @media (min-width: 768px) {  
    .water-mark {
      padding: 100px;  
    } 
    .WaterMark-main {
        display: flex;
        flex-direction: row;
        justify-content:space-evenly;
    }

    .image-preview {
        display: flex;
        align-items: center;
        flex-direction: column; 
        width: 600px;
        height: 400px;
    }

    .image-control {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 40px 40px;
    }

    /* .preview-image {
      width: 600px;
      height: 400px;
    } */
  
    .output-file {
      padding: 15px;
      max-width: 400px;
    }
  
    .save-button {
      max-width: 300px;
    }
  }
  
  /* Mobile 스타일 */
  @media (max-width: 767px) {
    .water-mark {
      padding: 80px;
    }
  
    .water-mark-header h1 {
      font-size: 2rem;
    }
  
    .instruction-text {
      font-size: 1rem;
    }
  
    .image-preview {
      width: 200px;
      height: 200px;
      margin: 10px auto;
    }
  
    .output-file {
      padding: 10px;
      max-width: 90%; /* 화면 크기에 맞게 조정 */
    }
  
    .format-label {
      font-size: 1rem;
    }
  
    .format-dropdown {
      font-size: 0.9rem;
    }
  
    .save-button {
      padding: 8px 15px;
      font-size: 0.9rem;
      max-width: 100%;
    }
  }
  

  .message {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #269830; /* Green color for success messages */
    text-align: center;
  }
  


  .size-dropdown {
    padding: 8px 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
    margin-bottom: 15px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .size-dropdown:hover,
  .size-dropdown:focus {
    border-color: #0069d9; /* 강조 색상 */
  }
  
  label {
    font-size: 1rem;
    color: #333;
    margin-right: 10px;
  }
  
  input[type="number"] {
    padding: 8px 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    width: 100px;
    margin-right: 10px;
    transition: border-color 0.3s ease-in-out;
  }
  
  input[type="number"]:focus {
    border-color: #0069d9; /* 강조 색상 */
    box-shadow: 0 0 5px rgba(0, 105, 217, 0.5);
  }
  
  input[type="number"]::placeholder {
    color: #aaa;
    font-style: italic;
  }
  
  input[type="number"]:hover {
    border-color: #0069d9;
  }
  
  .custom-size-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .custom-size-container label {
    font-size: 0.9rem;
    color: #555;
  }
  
  .custom-size-container input[type="number"] {
    flex-shrink: 0;
    max-width: 100px;
  }
  
  .inputNewSize {
    display: flex;
    flex-direction: column;
  }

  .watermarkTextContainer {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-top: 10px;
    margin-left: 20px;
  }
  
  .watermark-label { 
    font-size: 16px;
    font-weight: bold;  
    color: #333; /* Dark gray color for the label text */
  }
  
  .watermark-input {
    width: 60%;
    max-width: 400px; /* Optional: Set a maximum width */
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 4px;
    outline: none;
    box-sizing: border-box; /* Ensures padding is included in width/height */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .watermark-input:focus {
    border-color: #007bff; /* Blue border when focused */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Light blue shadow on focus */
  }
  
  .watermark-input::placeholder {
    color: #aaa; /* Light gray placeholder text */
    font-style: italic;
  }
  