.huggingface-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;  
    padding: 100px;
    max-width: 500px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .input-section, .output-section {
    width: 45%;
    display: flex;
    flex-direction: column; 
    align-items: center;
  }
  
  .input-section h2, .output-section h2 {
    text-align: center;
    color: #333;
    margin-bottom: 15px;
  }
  
  .input-box {
    display: flex;
    flex-direction: column; 
    align-items: center;
    width: 400px;
    margin-bottom: 20px;
  }
  
  .input-box label {
    font-weight: bold;
    margin-bottom: 5px;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
  }
  
  .input-box textarea {
    text-align: left;
    padding: 10px;
    width: 90%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    resize: none;
  }
  
  .huggingfaceButton {
    width: 300px;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    /* transition: background-color 0.3s ease; */
  }
  
  .huggingfaceButton:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  .huggingfaceButton:hover:not(:disabled) {
    background-color: #45a049;
  }
  
  .output-section .image-display {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 350px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .output-section img { 
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  

  .loading-animation {
    position: absolute;
    top: -40px; /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 14px;
    color: #555;
  }
  
  .spinner {
    width: 30px;
    height: 30px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  