/* Home.css */



.splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-white);
  z-index: 10;
  opacity: 0;
  animation: fadeInOut 1s forwards; /* Increased animation duration */
}

.splash-logo {
  width: 150px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}



.Home {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 100vh; /* 화면 높이보다 20% 더 높게 설정 */
  padding-bottom: 50px; /* 하단에 여유 공간 추가 */
}

/* Home-header 기본 스타일 */
.Home-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: #f5f5f5; /* 부드러운 배경색 */
  width: 100%;
  height: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 살짝 그림자 효과 */
}

.Home-header h1 {
  font-size: var(--font-size-h1);
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.Home-header p {
  font-size: var(--font-size-default);
  color: #666;
  text-align: center;
  max-width: 600px; /* 내용이 너무 길지 않도록 제한 */
  margin: 0 auto;
}



/* .toGo nav a {
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: 'Arial', sans-serif;
  text-decoration: none;
  padding: 8px 16px;
  background-color: #334811;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toGo nav a:hover {
  background-color: #269830;
  color: #e0f7fa;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
} */

.HomeContainer {
  display: flex; 
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;
  border-radius: 0px;
  margin: 0px 0px;
  padding: 0;
  justify-content: center; /* 좌우 정렬 */
  /* background-color: var(--color-white); */
  opacity: 1;
  /* max-height: 50vh; */
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding-right: 0px;
  /* 배경 이미지 추가 */
  /* background-image: url('../public/images/patternImage.png');   */
  /* background-repeat: repeat; 문양 반복 */
  background-size: cover;   /* 배경 이미지를 컨테이너 크기에 맞춤 */
  background-position: center;  
  overflow-x: hidden; /* 수평 스크롤 숨기기 */
}

.myHomeTitle {
  display: flex;
  gap: 0px;
  justify-content: center;
  margin-top: 0px;
  align-items: center;  
  flex-direction: column;
}
 

.home-Greetings {
  /* background-color: rgba(48, 8, 194, 0.2); */
  color: var(--color-blue);
  border: none;
  /* border: 1px solid var(--color-lightgray); */
  padding: 0px 0px;
  /* border-radius: 16px; */
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  opacity: 1.0;
  /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); */
  margin-top: 60px;
  overflow-wrap: normal;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.5s ease, opacity 0.5s ease;
}

.home-Greetings:hover {
  transform: scale(1.1);
  opacity: 1;
  /* text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.9); */
}

.home-subtitles {
  margin-top: 0px;
}



.home-tab-buttons {
  display: flex;
  gap: 20px;
  row-gap: auto; /* vertical gap */
  column-gap: auto; /* horizontal gap */
  justify-content: center;
  margin-top: 30px; 
}

.home-tab-buttons button {
  background: transparent; 
  border: none;  
  color: #333;  
  cursor: pointer; 
  font-size: 16px; 
    /* padding: 10px 20px; 버튼 내부 여백 */
}

.mainContents {
  display: flex;
  flex-direction: column;
}

.advertisement {
  display: flex;
  background: transparent; 
  margin-top: 10px; 
}


.home-clipboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

.home-clipboard-image {
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.home-clipboard-image:hover {
  transform: scale(1.01); /* 확대 효과 */
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); */
}

.MusicPlayerContainer {
  margin-bottom: 10px;
}
 

