/* src/styles.css 또는 src/global.css */
:root {
    --color-blue: #3498db;
    --color-green: rgb(118, 224, 162, 0.8); 
    --color-gray: #333; 
    --color-white: #e5e9e9;  
    --color-whiter: #f9f5f5;
    --color-black: #090909;  
    --color-yellow: #d1e123;  
    --color-orange: rgba(246, 124, 25, 0.9); 
    --color-orangeA: rgba(230, 188, 154, 0.8);  
    --color-lightgray: rgba(255, 255, 255, 0.3); 
    --color-lightergray: #c2bbbb; 

    --font-size-h1: 2.0rem;
    --font-size-default: 1.0rem;
    --font-size-title: 1.2rem;
    --font-size-title-mobile: 0.7rem;
    --font-size-input: 0.8rem;
    --font-size-button: 0.8rem;   

    --font-size-card-title: 0.7rem;

    --background-color-button:#3498db;
  }
  
/* .site-buttons{
    --color-lightgray: rgba(242, 148, 116, 0.6);

  } */