/* SplashScreen.css */
.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
  }
  
  .splash-logo {
    width: 200px; /* Adjust as needed */
    height: auto;
  }
  