.music-player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: gray;
    border: 1px solid gray;
    border-radius: 8px;
    padding: 10px;
    width: 320px;
    margin: 20px auto;
  }
  
  .waveform {
    width: 90%;
    height: 20px;
    margin-bottom: 10px;
  }
  
  .music-controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .control-button {
    background-color: gray;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .control-button:hover {
    background-color: darkgray;
  }
  