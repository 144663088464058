/* Diary.css */



.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 1000;
    opacity: 0;
    animation: fadeInOut 4s forwards; /* Increased animation duration */
  }
  
  .splash-logo {
    width: 150px;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  
  
  .Diary {
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 100vh; /* 화면 높이보다 20% 더 높게 설정 */
    padding-bottom: 50px; /* 하단에 여유 공간 추가 */
  }
  
  /* Diary-header 기본 스타일 */
  .Diary-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background-color: #f5f5f5; /* 부드러운 배경색 */
    width: 100%;
    height: 65px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 살짝 그림자 효과 */
  }
  
  .Diary-header h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .Diary-header p {
    font-size: 1rem;
    color: #666;
    text-align: center;
    max-width: 600px; /* 내용이 너무 길지 않도록 제한 */
    margin: 0 auto;
  }
  
  
  
  /* .toGo nav a {
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: 'Arial', sans-serif;
    text-decoration: none;
    padding: 8px 16px;
    background-color: #334811;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .toGo nav a:hover {
    background-color: #269830;
    color: #e0f7fa;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  } */
  
  .myBookmarkContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 0px;
    margin: 0px 0px;
    padding: 0;
    justify-content: center; /* 좌우 정렬 */
    background-color: var(--color-white);
    opacity: 1;
    /* max-height: 50vh; */
    width: 100vw;
    overflow-y: auto;
    padding-right: 0px;
    /* 배경 이미지 추가 */
    background-image: url('../public/images/patternImage.png'); /* 문양 이미지 경로 */
    /* background-repeat: repeat; 문양 반복 */
    background-size: auto; /* 이미지 크기를 자동으로 유지 */
    background-position: center; /* 문양 위치 설정 */   
  }
  
  .myBookmarkTitle {
    display: flex;
    gap: 0px;
    justify-content: center;
    margin-top: 5px;
    align-items: center;  
    flex-direction: column;
  }
  
  .Greetings {
    /* background-color: rgba(48, 8, 194, 0.2); */
    color: var(--color-blue);
    border: none;
    /* border: 1px solid var(--color-lightgray); */
    padding: 0px 10px;
    /* border-radius: 16px; */
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    opacity: 0.8;
    /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); */
    margin-top: 10px;
    overflow-wrap: normal;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.5s ease, opacity 0.5s ease;
  }
  
  .Greetings:hover {
    transform: scale(1.1);
    opacity: 1;
    /* text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.9); */
  }
  
  .subtitles {
    margin-top: 0px;
  }
  
  .myBookmarks {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 0px;
    margin: 0px 0px;
    justify-content: center;
    background-color: var(--color-orangeA);
    opacity: 1.0;
    /* max-height: 80vh; */
    width: 100vw;
    height: 60vh;
    overflow-y: auto;
    padding-right: 0px;  
  } 
  
  .tab-buttons {
    display: flex;
    /* gap: 10px; */
    row-gap: 20px; /* vertical gap */
    column-gap: 20px; /* horizontal gap */
    justify-content: center;
    margin-top: 1px;
    margin-bottom: 1px; 
  }
  
  .tab-buttons button {
    padding: 5px 20px;
    border: none;
    border-width: 1px;
    border-color: var(--color-lightgray);
    border-radius: 15px;
    background-color: var(--color-gray);
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 0px;
  }
  
  .tab-buttons button.active {
    background-color: var(--color-orange);
    font-weight: plain;
    font-size: 1.0em;
  }
  
  .tab-buttons button.inactive {
    background-color: rgb(203, 176, 176);
    font-weight: plain;
    font-size: 1.0em;
  }
  
  .default-sites, .mySites, .sharedSites, .utilitylist { 
    display: flex; 
    flex-wrap: wrap;
    /* gap: 10px; */
    row-gap: 10px;
    column-gap: 10px;
    border-radius: 10px; /* 요소들 사이의 상하 및 좌우 간격 */
    margin: 10px 0;
    padding: 0;
    justify-content: center;
    background-color: var(--color-orangeA);
    opacity: 1; 
    width: 98vw;
    height: auto;
    overflow-y: auto;
    padding-right: 0px;
  } 
  
  .add-site-form {
    display: flex;
    gap: 8px;
    margin: 20px 0;
    justify-content: center;
  }
  
  .add-site-form input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .editingTitleInput {
    display: block;
    padding: 0px;
    border: none;
    border-radius: 4px;
    width: 150px;
    resize: none; /* 크기 조절 비활성화 */
    overflow: hidden; /* 스크롤 바 숨김 */
    word-wrap: break-word; /* 긴 단어 줄바꿈 */
    white-space: pre-wrap; /* 입력 중 줄바꿈 처리 */
    font-size: 0.9rem;
    line-height: 1.0; /* 줄 간격 */
    min-height: calc(1.0em * 1); /* 기본 높이: 4줄 */
    max-height: calc(1.0em * 5); /* 최대 높이: 8줄 (선택 사항) */
    font-weight: plain; 
    font-family: 'Arial', sans-serif; /* Font stack with fallback */
  }
  
  .add-site-confirm-button {
    background-color: var(--color-white);
    opacity: 0.7;
    display: flex;
    justify-content: center;
    border: none;
    cursor: pointer;
    color: green;
    font-size: 1.2rem;
  } 
  
  .add-site-confirm-button:hover {
    background-color: var(--color-whiter);
  }
  
  
  .site-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 5px;
    /* background: transparent; */
    background-color: var(--color-white);
    border-radius: 8px; 
    margin: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 90px;
  }
  
  .site-thumbnail {
    width: 100%;
    object-fit: cover;
    transition: transform 0.3s;
    object-fit: contain; /* 이미지 비율을 유지하며 크기에 맞추기 */ 
    display: flex;
    align-items: center; /* 수직 중앙 정렬 */
    justify-content: center; /* 가로 중앙 정렬 */
    height: 45%; /* 부모 컨테이너의 전체 높이를 차지하게 설정 */
    /* border-bottom: 1px solid var(--color-whiter); */ 
  }
  
  .site-title {
    font-size: 0.9rem;
    font-weight: normal; /* plain은 올바르지 않은 값입니다 */
    text-align: center;
    display: flex;
    align-items: top; /* 수직 중앙 정렬 */
    justify-content: center; /* 가로 중앙 정렬 */
    height: 40%; /* 부모 컨테이너의 전체 높이를 차지하게 설정 */
    font-weight: plain; 
    font-family: 'Arial', sans-serif; /* Font stack with fallback */
  }
  
  
  /* cardAction 스타일 */
  .cardAction {
    display: flex;
    justify-content: space-between; /* 버튼들 간격 고르게 분배 */
    align-items: end;
    width: 100%; /* cardAction이 site-card 너비 전체 차지 */
    padding: 0px 4px;
    /* border-top: 1px solid #ccc; 윗부분 구분선 */
    position: absolute;
    bottom: 0px; /* site-card의 하단에 고정 */
    margin-bottom: 0px; 
    margin-top: auto;
  } 
  
  .delete-button {
    /* position: absolute; */
    /* top: 5px; */
    /* left: 2px; */
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--color-lightergray);
    font-size: 0.85rem;
    transition: color 0.3s ease;
  }
  
  .delete-button:hover {
    color: #ef4df5;
    font-size: 1rem;
    background-color: transparent;
  }
  
  /* .copy-button { */
    /* margin-left: auto; 항상 오른쪽 끝에 배치 */
  /* } */
  
  /* .copy-button:hover { */
    /* color: #ef4df5; */
  /* } */
   
  
  .tooltip {
    position: absolute;
    background-color: var(--color-orange);
    color: var(--color-white);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0.8; 
    white-space: nowrap;
  
    /* 애니메이션 추가 */
    animation: fadeOut 5s forwards;
  }
  
  
  .message_to_new_visitors {
    margin-top: 0px; /* Adjusts spacing below the button */
    font-size: 0.9rem; /* Optional: adjust text size */
    text-align: center; /* Centers text below the button */
    color: #555; /* Optional: adjust text color */
  }
  
  /* Desktop Styles */
  @media (min-width: 768px) {
    .default-sites { 
      gap: 10px;
    }
  
    .mySites { 
      gap: 10px;
    }
  
    .site-buttons {
      padding: 10px;
    }
  
    .site-card {
      width: 150px;
      padding: 5px;
      min-height: 200px;
    }
  
    .site-thumbnail {
      height: 75px;
    }
  
    .site-title {
      font-size: 0.9rem;
    }
   
  }
  
  /* Mobile Styles */
  @media (max-width: 767px) {
    /* .Greetings {
      font-size: 1.4rem;
      margin-top: 12vh;
    } */
  
    .default-sites { 
      gap: 5px;
    } 
  
    .mySites { 
      gap: 5px;
    }
  
    .sharedSites {
      gap: 5px;
    } 
  
    .tab-buttons button.active { 
      font-size: 0.9em;
    }
    
    .tab-buttons button.inactive { 
      font-size: 0.9em;
    }
  
    .site-buttons {
      padding: 10px;
      max-height: 50vh;
    }
     
  
    .site-card {
      width: 100px;
      padding: 3px;
      min-height: 140px;
    }
  
    .site-thumbnail {
      height: 50px;
    }
  
    .site-title {
      font-size: 0.8rem;
    } 
  }
  
  /* MyComponent.css */
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.01); /* 반투명 배경 */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 300px;
  }
  
  .popup input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
  }
  
  .popup button {
    margin: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-button, .cancel-button {
    margin: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-button {
    background-color: #d9534f;
    color: white;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: black;
  }
  
  .share-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #c2bbbb;
    font-size: 0.8rem;
    margin-left: auto;  
  } 
  
  .share-button:hover {
    color: #ef4df5;
    font-size: 1rem;
    background-color: transparent;
  }
  
  
  .marketNewsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 10px;
    margin: 30px 0px;
    padding: 0;
    justify-content: center;
    background-color: var(--color-white);
    opacity: 1;
    /* max-height: 50vh; */
    width: 100vw;
    overflow-y: auto;
    padding-right: 0px;
  }
  
  
  /* Overlay background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-white); /* semi-transparent background */
    z-index: 999; /* Behind the overlay-content but above other content */
  }
  
  /* Overlay content box */
  .overlay-content {
    background: rgb(255, 255, 255,0.3);
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }
  
  /* Share options for checkboxes */
  .share-options {
    margin: 10px 0;
  }
  
  .share-options label {
    display: block;
    margin-bottom: 8px;
  }
   
  
  .share-site {
    background: none;
    border: none;
    cursor: pointer;
    color: #c2bbbb;
    font-size: 0.8rem;
    margin-left: auto;  
  } 
  
  .share-site:hover {
    color: #ef4df5;
    font-size: 1rem;
    background-color: transparent;
  } 
  
  
  
  
  .TGabyss {
    position: relative;
    bottom: 0;
    color: white;
    text-align: left;
    padding: 2px 15px;
    font-size: 0.7rem;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 10px;
    border-top: 1px solid #575854;
    opacity: 0.5;
  }