.Diary {
    font-family: Arial, sans-serif;
    padding: auto; 
    margin-top: 55px;
    overflow: "visible";
}
 

.Greetings-clipboard {
    /* background-color: rgba(48, 8, 194, 0.2); */
    color: var(--color-blue);
    border: none;
    /* border: 1px solid var(--color-lightgray); */
    padding: 0px 20px;
    /* border-radius: 16px; */
    font-size: 1.0rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    opacity: 0.8;
    /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); */
    margin-top: 20px;
    margin-bottom: 20px;
    overflow-wrap: normal;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.5s ease, opacity 0.5s ease;
  }
  
  .Greetings:hover {
    transform: scale(1.1);
    opacity: 1;
    /* text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.9); */
  }

  .myDiaryContainer {
    width: 100vw;
    height: 100vh;
  }

  .myDiary {
    display:flex;
    justify-content: center;
    width: 100%;
    height: 80%;
  }
 
  .DiaryForm {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 95%; 
    /* height: 100%; */
    padding: 0px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px; 
    overflow: "visible";
  }
    
  .DiaryForm h2 {
    text-align: center;
    color: #333;
  }

  .dateSelection {  
    padding: 1%;
    /* margin-left: 5%; */
    flex: 1; /* Let the input take up remaining space */
    width: 300px; 
  }

  .textArea { 
    display:flex;
    flex-direction: column;
    justify-content: flex-start; /* 세로 정렬 */
    align-items: flex-start; 
    padding: 0px;
    border: none;
    border-radius: 4px;
    background: white; 
    /* margin-bottom: 16px; */
    overflow-y: scroll;     
  }  

  .textArea img {
    max-width: 98%; /* 가로 크기를 컨테이너 너비에 맞춤 */
    height: auto; /* 비율 유지 */
    max-height: 50%; /* 최대 높이 제한 (필요시 추가) */
    object-fit: contain; /* 이미지 비율 유지 */
  } 

  .tab-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .tab-buttons button {
    padding: 5px 20px;
    /* border: none; */
    border-width: 1px;
    border-color: var(--color-orange);
    border-radius: 15px;
    background-color: var(--color-white);
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 0px; 
  }
  
  .tab-buttons button.active {
    background-color: var(--color-green);
    font-weight: plain;
  } 

.DiaryList .diary-entry {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px; 
} 


  
  .form-group {
    margin-bottom: 10px;
    margin-top: 5px;
    width: 100%;
  }
  
  .form-group label {
    display:flexbox; /* Ensure the label and input align properly */
    width: 100px;  
    font-weight: bold;
    margin-right: 10px; /* Add some space between the label and input */
    margin-left: 10px;
  }
  
  /* .form-group input[type="date"], */
  /* .form-group input[type="time"], */
  /* .form-group input[type="file"],  */
  
  .form-group button {
    background-color: #4CAF50; /* Green background */
    color: rgb(255, 255, 255); /* White text */
    border: none; /* Remove default border */
    border-radius: 4px; /* Rounded corners */
    padding: 10px 20px; /* Padding for better click area */
    font-size: 16px; /* Font size for readability */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor for hover state */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transition effects */
  }

  .submitButton {
    background-color: #4CAF50; /* Green background */
    color: rgb(255, 255, 255); /* White text */
    border: none; /* Remove default border */
    border-radius: 4px; /* Rounded corners */
    padding: 5px 50px; /* Padding for better click area */
    font-size: 16px; /* Font size for readability */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor for hover state */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transition effects */
    margin-top: 15px;
  }  

/* Desktop device */
@media (min-width: 768px) {  
  .submitButton {
    margin-bottom: 30%;
  }
  .textArea {  
    width: 90%; 
    height: auto;
    min-height: 80%;
  } 
  .form-group input[type="date"] {
    max-width: 200px; /* Limit the maximum width */
    min-width: 200px;
    width: 200px;    
    font-size: 16px; /* 원하는 폰트 크기 */
    padding: 5px; /* 입력 필드 안쪽 여백 */
    border-radius: 4px; /* 선택사항: 모서리를 둥글게 */ 
    margin-top: 5px;
  }
  .ql-editor {
    font-size: 12; /* 폰트 크기 */
    font-style: normal;
    font-weight:normal;
    line-height: 1.4; /* 줄 간격 */
  } 
  .fileUpload {
    margin-top: 1%;
  }
}

/* Mobile device */
@media (max-width: 767px) {
  .submitButton {
    margin-bottom: 100px;
  }

  .textArea {  
    width: 90%;  
    height: 62%;
    min-height: 62%; 
  } 

  .form-group input[type="date"] {
    max-width: 200px; /* Limit the maximum width */
    min-width: 200px;
    width: 200px;   
    font-size: 12px; /* 원하는 폰트 크기 */
    padding: 5px; /* 입력 필드 안쪽 여백 */
    border-radius: 4px; /* 선택사항: 모서리를 둥글게 */ 
    margin-top: 5px;
  }  
  .ql-editor {
    font-size: 12; /* 폰트 크기 */
    font-style: normal;
    font-weight:normal;
    line-height: 1.4; /* 줄 간격 */
  } 
  .fileUpload {
    margin-top: 5%;
    font-size: 10;
  }
  
}  

.ql-syntax {
  background-color: lightgray !important; /* 어두운 배경 색상 */
  color: black !important; /* 글자 색상 */
  padding: 10px;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
}


  
  button:hover {
    background-color: #218727;
  }
  
  .status-message {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #555;
  }
  
  .tooltip {
    position: absolute;
    background-color: var(--color-orange);
    color: var(--color-white);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0.8; 
    white-space: nowrap;
  
    /* 애니메이션 추가 */
    animation: fadeOut 5s forwards;
  }



  .diary-list-container {
    display: flex;
    justify-content: center;
    margin: 8px auto;
    padding: 0px;
    max-width: 90vw;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(60, 59, 59, 0.1);
    width: 90vw;
    /* max-height: 100vh; */
    margin-left: 2%;
  }

.diary-list-container h1 {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.diary-list {
    display: flex;
    flex-direction: column; 
    gap: 5px; 
    width: 98%; /* 전체 너비를 사용 */
    height: 75vh; /* 높이를 뷰포트 높이의 60%로 설정 */
    overflow-y: auto; /* 세로 스크롤바 추가 */
    padding: 0px; /* 내부 여백 */
    border: 1px solid #ddd; /* 선택사항: 테두리 추가 */
    border-radius: 8px; /* 선택사항: 모서리를 둥글게 */
    background-color: #fafaf9; /* 선택사항: 배경색 설정 */
}

/* Mobile device */
@media (max-width: 767px) {
  .diary-list-container { 
    margin-left: 2%;
  }
}

.diary-item {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
}

.diary-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.diary-item .diary-date {
    font-size: 16px;
    font-weight: bold;
    color: #555;
    margin-bottom: 8px;
}

.diary-item .diary-preview {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
    display: flex; /* Flexbox를 사용하여 요소를 가로로 배치 */
    align-items: center; /* 요소를 세로로 정렬 */
    gap: 10px; /* 요소 간 간격 */
}

.diary-item .diary-preview span {
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  overflow: hidden; /* 길이가 긴 텍스트는 숨김 */
  text-overflow: ellipsis; /* 숨겨진 텍스트에 '...' 추가 */
}

.diary-item-content {
  display: flex; /* Flexbox로 가로 정렬 */
  justify-content:left;
  align-items: center; /* 세로 중앙 정렬 */
  gap: 15px; /* 각 요소 간 간격 */
  font-size: 14px; /* 모든 텍스트의 폰트 크기를 동일하게 설정 */
  white-space: wrap; /* 텍스트가 한 줄로 유지되도록 설정 */
  overflow: hidden; /* 텍스트가 너무 길 경우 잘림 */
  text-overflow: ellipsis; /* 잘린 텍스트에 '...' 표시 */ 
}

.diary-item-content span {
  display:flex; /* 각 요소를 블록처럼 취급 */ 
  width: auto;
  overflow: wrap;
}


.diary-item .diary-file {
    font-size: 12px;
    color: #999;
    font-style: italic;
}

.error-message {
    color: #d9534f;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
}

.diary-details {
    margin-top: 10px;
    padding: 0px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.diary-details h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.diary-details p {
    margin: 0px 0;
    font-size: 16px;
    color: #555;
    line-height: 1.6;
} 

.diary-details .ReactQuill {
  margin-bottom: 10px;
}

.diary-details button {
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.diary-details input[type="file"] {
  margin-top: 10px;
}

.diary-text {
  width: 100%; /* 필요한 경우 설정 */
  height: auto; /* 텍스트 높이에 따라 자동 조정 */
  line-height: 1.0; /* 텍스트 줄 간격 */
  overflow-wrap: break-word; /* 긴 단어 줄바꿈 */
  text-align:justify;
  margin-top: 0px;
} 

.diary-photo img {
    max-width: 100%;
    border-radius: 8px;
    margin-top: 10px;
}

.memo-topinfo{
  border-bottom: 1px solid #ccc; /* 윗부분 구분선 */
  margin-left: 5%;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  width: 89%;
}

.bottom-buttons {  
    display: flex;
    flex-direction: row; 
    justify-content: space-between; /* 버튼들 간격 고르게 분배 */
    /* align-items: end;  */
    /* padding: 0px 4px; */
    border-top: 1px solid #ccc; /* 윗부분 구분선 */
}


.close-details-icon {
  /* display: block; */
  margin: 10px auto 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.attachedFiles {
  margin: 0px auto 0;
}

.close-details-button {
    /* display: block; */
    margin: 10px auto 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.close-details-button:hover {
    background-color: #0056b3;
}

.delete-button {
  /* display: block; */
  margin: 10px auto 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #0056b3;
}

.diary-photo {
  width: 100%; /* 컨테이너의 전체 너비 사용 */
  text-align: center; /* 이미지가 가운데 정렬되도록 */
  margin: 0 auto; /* 가운데 정렬 */
}

.diary-photo img {
  width: 100%; /* 이미지 너비를 컨테이너의 90%로 제한 */
  height: auto; /* 비율을 유지하면서 크기 조정 */
  border-radius: 8px; /* 선택사항: 이미지 모서리를 둥글게 만듦 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 선택사항: 이미지에 그림자 추가 */
}


.diary-text img {
  max-width: 100%; /* 이미지가 부모 컨테이너의 너비를 넘지 않도록 제한 */
  height: auto; /* 이미지의 비율을 유지 */
  display: block; /* 텍스트와의 간격을 조정하기 위해 블록 요소로 표시 */
  margin: 10px auto; /* 가운데 정렬 및 여백 추가 */
  border-radius: 8px; /* 선택사항: 이미지 모서리를 둥글게 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 선택사항: 이미지에 그림자 추가 */
}

.diary-text div {
  margin: 0;
  padding: 5%;
}
.diary-text div[style*="text-align: left"] {
  text-align: left !important;
}
.diary-text div[style*="text-align: center"] {
  text-align: center !important;
}
.diary-text div[style*="text-align: right"] {
  text-align: right !important;
}



 


audio {
  width: 100%; /* 너비를 화면에 맞추기 */
  height: 50px; /* 높이를 설정 */
  background-color: #f4f4f4; /* 배경 색상 */
  border: 1px solid #ccc; /* 테두리 추가 */
  border-radius: 5px; /* 둥근 모서리 */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
}

.audio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #ffffff; /* 배경색 */
  border: 1px solid #ddd; /* 테두리 */
  border-radius: 8px; /* 둥근 모서리 */
  max-width: 400px; /* 최대 너비 */
  margin: auto; /* 중앙 정렬 */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}


.custom-audio-player {
  display: flex;
  align-items: center;
  justify-content:center;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: auto;
  font-family: Arial, sans-serif;
}

button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

button:hover {
  color: #007bff;
}

.progress-bar {
  flex: 1;
  height: 5px;
  background: #ddd;
  border-radius: 5px;
  margin: 0 10px;
  position: relative;
  overflow: hidden;
}

.progress {
  width: 0%;
  height: 100%;
  background: #007bff;
  border-radius: 5px;
  transition: width 0.1s linear;
}

#current-time,
#duration {
  font-size: 14px;
  color: #333;
}


.viewer-container {
  overflow: hidden; /* 외부 영역 숨김 */
  position: relative;
  touch-action: none; /* 기본 터치 동작 차단 */
  max-width: 100%; /* viewer 크기 고정 */
}

.zoomable-content {
  touch-action: manipulation; /* 터치 동작 허용 */
  user-select: none; /* 텍스트 선택 방지 */
  transform-origin: center center; /* 확대/축소 기준점 */
  transition: transform 0.3s ease; /* 부드러운 확대/축소 */
}

pre {
  background: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  white-space: pre-wrap;
}

/* ReactQuill의 기본 설정에서는 비디오가 <iframe> 태그로 삽입됩니다. 해당 태그에 CSS 스타일을 적용하여 크기를 조정할 수 있습니다. */
.ql-editor iframe {
  resize: both; /* 가로, 세로 크기 조정 허용 */
  overflow: auto; /* 내용 스크롤 가능 */
  border: 1px solid #ddd; /* 크기 조절 시 윤곽선 표시 */
  max-width: 100%; /* 반응형 크기 제한 */ 
}

