/* App.css 파일의 상단에 추가 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* 박스 모델을 정확히 제어하기 위해 */
}


html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior-x: none;
  /* overscroll-behavior-y: none;  */
  overscroll-behavior-y: contain; 
}

body {
  overflow: hidden;
}

.App {
  text-align: center; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden; /* 전체 화면에서 스크롤이 생기지 않도록 설정 */
}

.App-logo {
  height: 40vmin;
}

.App-header { 
  height: 100vh;
  flex-grow: 1;
  text-align: center;
  justify-content: flex-start; /* 위쪽으로 정렬 */
  background-color: var(--color-white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: var(--color-black);
  padding: 0; /* 패딩을 0으로 설정하여 여백 제거 */
  margin: 0;  /* 마진을 0으로 설정하여 여백 제거 */
  width: 100%; /* 전체 너비를 차지하도록 설정 */
  box-sizing: border-box; /* 패딩이 박스 크기에 영향을 주지 않도록 */
  padding-top: 0px;
}

/* 전체 topmenu 스타일 */
.topmenu {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--color-white);
  color: var(--color-gray);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* 상단 그림자 효과 */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  flex-shrink: 0; /* 상단 메뉴가 고정되도록 설정 */
}

/* 왼쪽 섹션 (회사 로고나 문구) */
.topmenu .logo {
  font-size: 1.0rem;
  font-weight: bold;
}

.logo {
  height: 30px; /* 로고 크기 조절 */
  margin-right: 10px; /* 메뉴와 간격 설정 */
  margin-top: 10px;
}

/* 버튼 컨테이너 (로그인/로그아웃과 언어 선택 버튼) */
.topmenu .googleLogin-button,
.topmenu .language-button {
  display: inline-flex;
  align-items: center;
}

.content {
  /* flex: 1; */
  overflow-y: auto; /* 메인 컨텐츠 영역에서만 스크롤 가능 */
}

/* 로그인/로그아웃 버튼 스타일 */  
@keyframes shimmer {
  0% {
    color: var(--color-white); 
  }
  50% {
    color: var(--color-green);
  }
  100% {
    color: var(--color-gray); 
  }
}

.googleLogin-button {
  background-color: transparent;
  color: var(--color-gray);
  border: none;
  padding: 8px 12px;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  animation: shimmer  1.5s ease-in-out; 
  opacity: 0.6;
}

.googleLogin-button:hover {
  background-color: var(--color-orange); /* 호버 시 어두운 녹색 */
  animation: none; /* hover 시 반짝임 해제 */
}

.naverLogin-button {
  background-color: transparent;
  color: var(--color-gray);
  border: none;
  padding: 8px 12px;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  animation: shimmer  1.5s ease-in-out; 
  opacity: 0.6;
  flex-wrap: wrap;
}

.naverLogin-button:hover {
  background-color: var(--color-green); /* 호버 시 어두운 녹색 */
  animation: none; /* hover 시 반짝임 해제 */
}

.user-section {
  display: flex;
  /* align-items: self-end; */
  gap: 10px; /* 버튼과 이미지 간격 */
  margin-left: auto; 
}

.profile-container {
  display: flex;
  align-items: center; /* 프로필 사진과 이름 수직 정렬 */
  gap: 10px; /* 프로필 사진과 이름 간격 */
}

.user-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* 원형으로 표시 */
  object-fit: cover; /* 이미지 비율 유지 */
  border: 2px solid #ccc; /* 선택적 테두리 */
}

.user-display-name { 
  font-weight: plain;
  color: #333; /* 이름 색상 */
}

.logout-button {
  background-color: transparent;
  color: var(--color-gray);
  border: none;
  padding: 8px 12px;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  animation: shimmer  1.5s ease-in-out; 
  opacity: 0.6;
  width: 100px; 
}

.logout-button:hover {
  background-color: var(--color-orange); /* 호버 시 어두운 녹색 */
  animation: none; /* hover 시 반짝임 해제 */
}

/* 언어 변경 버튼 스타일 */
.language-button {
  background-color: transparent;
  color: var(--color-gray);
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  opacity: 0.6;
  width: 100px;
  display: flex;
  justify-content: center; /* 모든 항목을 오른쪽으로 배치 */
}

.language-button:hover {
  background-color: var(--color-orange); /* 호버 시 더 진한 노란색 */
}

/* 모바일 최적화 (작은 화면에 맞춤) */
@media (max-width: 600px) {
  .topmenu {
    padding: 8px;
    font-size: 0.9rem;
  }

  .googleLogin-button, naverLogin-button,
  .language-button {
    padding: 6px 10px;
    font-size: 0.8rem;
  }
}
 

.user-profile-picture {
  width: 40px; /* 이미지 크기 */
  height: 40px; /* 이미지 크기 */
  border-radius: 50%; /* 원형으로 표시 */
  object-fit: cover; /* 이미지 비율 유지 */
  border: 2px solid #ccc; /* 선택적 테두리 추가 */
}