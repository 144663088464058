/* Global styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
  }
  
  /* Container for the game */
  .HeadsUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  /* Age group selection */
  .age-group-selection {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .age-group-selection h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .age-group-selection button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .age-group-selection button:hover {
    background-color: #0056b3;
  }
  
  /* Start screen */
  .start-screen {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .start-screen h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .start-screen button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #28a745;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .start-screen button:hover {
    background-color: #218838;
  }
  
  /* Game screen */
  .game-screen {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .game-screen h1 {
    font-size: 48px;
    margin: 20px 0;
  }
  
  .game-screen h2 {
    font-size: 36px;
    margin: 20px 0;
  }
  
  .game-controls button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ffc107;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .game-controls button:hover {
    background-color: #e0a800;
  }
  
  /* Score display */
  .score {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Reset button */
  .reset-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #dc3545;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .reset-button:hover {
    background-color: #c82333;
  }
  
  .game-screen .timer {
    font-size: 24px;
    font-weight: bold;
    color: red;
    margin-top: 10px;
  }
  
  .game-screen .score {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
  }

  .game-instructions {
    margin: 20px auto;
    padding: 20px;
    max-width: 600px; /* 중앙에 표시하고 폭을 제한 */
    border: 2px solid #4CAF50; /* 테두리를 초록색으로 */
    border-radius: 10px; /* 모서리를 둥글게 */
    background-color: #f9f9f9; /* 부드러운 배경색 */
    text-align: left; /* 텍스트를 왼쪽 정렬 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 약간의 그림자 추가 */
  }
  
  .game-instructions h2 {
    font-size: 24px; /* 큰 제목 글꼴 크기 */
    margin-bottom: 10px; /* 제목과 본문 간 간격 */
    color: #4CAF50; /* 초록색 제목 */
    font-weight: bold; /* 굵은 텍스트 */
  }
  
  .game-instructions p {
    font-size: 16px; /* 본문 글꼴 크기 */
    line-height: 1.6; /* 줄 간격 */
    color: #333; /* 다소 진한 본문 색상 */
  }
  
  

  /* Record list table */
.record-list {
  margin: 0 auto; /* 가로 중앙 정렬 */
  margin-bottom: 20px; 
}



.record-list h3 {
  font-size: 20px;
  color: #34495e;
  margin-bottom: 10px;
  margin-top: 20px;
}

.record-list table {
  margin: 0 auto; /* 가로 중앙 정렬 */
  width: 100%;
  border-collapse: collapse;
  background-color: #f9fbfd;
  
}

.record-list table tbody {
  display: block; 
  overflow-y: auto;
  width: 100%; /* 테이블 너비 맞추기 */
}

/* Desktop device */
@media (min-width: 768px) {  
  .record-list {
    max-width: 80%;
  }

  .record-list table tbody { 
    max-height: 300px; /* 원하는 높이 제한 */ 
  } 
 
}

/* Mobile device */
@media (max-width: 767px) { 
  .record-list {
    max-width: 85%;
  }

  .record-list table tbody { 
    max-height: 200px; /* 원하는 높이 제한 */ 
  }  
}

.record-list table thead,
.record-list table tbody tr {
  display: table; /* 테이블 구조 유지 */
  table-layout: fixed; /* 열 크기 고정 */
  width: 100%;
}

.record-list table th,
.record-list table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.record-list table th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}
