/* Home.css */



.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-white);
    z-index: 10;
    opacity: 0;
    animation: fadeInOut 1s forwards; /* Increased animation duration */
  }
  
  .splash-logo {
    width: 150px;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  
  
  .Home {
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 100vh; /* 화면 높이보다 20% 더 높게 설정 */
    padding-bottom: 50px; /* 하단에 여유 공간 추가 */
  }
  
  /* Home-header 기본 스타일 */
  .Home-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background-color: #f5f5f5; /* 부드러운 배경색 */
    width: 100%;
    height: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 살짝 그림자 효과 */
  }
  
  .Home-header h1 {
    font-size: var(--font-size-h1);
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .Home-header p {
    font-size: var(--font-size-default);
    color: #666;
    text-align: center;
    max-width: 600px; /* 내용이 너무 길지 않도록 제한 */
    margin: 0 auto;
  }
  
  
  
  /* .toGo nav a {
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: 'Arial', sans-serif;
    text-decoration: none;
    padding: 8px 16px;
    background-color: #334811;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .toGo nav a:hover {
    background-color: #269830;
    color: #e0f7fa;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  } */
  
  .HomeContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 0px;
    margin: 0px 0px;
    padding: 0;
    justify-content: center; /* 좌우 정렬 */
    /* background-color: var(--color-white); */
    opacity: 1;
    /* max-height: 50vh; */
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    padding-right: 0px;
    /* 배경 이미지 추가 */
    /* background-image: url('../public/images/patternImage.png');   */
    /* background-repeat: repeat; 문양 반복 */
    background-size: cover;   /* 배경 이미지를 컨테이너 크기에 맞춤 */
    background-position: center;  
    overflow-x: hidden; /* 수평 스크롤 숨기기 */
  }
  
  .myBookmarkContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 0px;
    margin: 0px 0px;
    padding: 0;
    justify-content: center; /* 좌우 정렬 */
    background-color: var(--color-white);
    opacity: 1;
    /* max-height: 50vh; */
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    padding-right: 0px;
    /* 배경 이미지 추가 */
    background-image: url('../public/images/patternImage.png');  
    /* background-repeat: repeat; 문양 반복 */
    background-size: cover;   /* 배경 이미지를 컨테이너 크기에 맞춤 */
    background-position: center;  
    overflow-x: hidden; /* 수평 스크롤 숨기기 */
  }
  
  .myBookmarkTitle {
    display: flex;
    gap: 0px;
    justify-content: center;
    margin-top: 0px;
    align-items: center;  
    flex-direction: column;
  }
  
  .Greetings-home {
    /* background-color: rgba(48, 8, 194, 0.2); */
    color: var(--color-blue);
    border: none;
    /* border: 1px solid var(--color-lightgray); */
    padding: 0px 10px;
    /* border-radius: 16px; */
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    opacity: 0.8;
    /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); */
    margin-top: 40px;
    overflow-wrap: normal;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.5s ease, opacity 0.5s ease;
  }
  
  .Greetings-home:hover {
    transform: scale(1.1);
    opacity: 1;
    /* text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.9); */
  }
  
  .subtitles {
    margin-top: 0px;
  }
  
  
  
  .tab-buttons {
    display: flex;
    /* gap: 10px; */
    row-gap: auto; /* vertical gap */
    column-gap: auto; /* horizontal gap */
    justify-content: center;
    margin-top: 1px;
    margin-bottom: 1px; 
  }
  
  .tab-buttons button {
    background: transparent; 
    border: none;  
    color: #333;  
    cursor: pointer; 
    font-size: 16px; 
      /* padding: 10px 20px; 버튼 내부 여백 */
  }
  
  /* Desktop device */ 
    /* .tab-buttons button.active {
      background-color: var(--color-orange);
      background: linear-gradient(to right, orange, yellow);  
      box-shadow: 
          0 3px 6px rgba(0, 0, 0, 0.3),  
          inset 0 0 0 3px #FFD700;  
      font-weight: normal;
      font-size: var(--font-size-title);
      font-family: 'Arial Rounded MT Bold', Arial, sans-serif;  
      font-weight: bold;  
      color: white; 
      text-shadow: 
        -1px -1px 0 #6b4226, 
        1px -1px 0 #6b4226, 
        -1px  1px 0 #6b4226, 
        1px  1px 0 #6b4226,  
        2px  2px 5px rgba(0, 0, 0, 0.5); 
      padding: 5px 10px; 
      border: none;  
      border-radius: 50%;  
      display: inline-block;  
      cursor: pointer;  
    } 
  
    .tab-buttons button.inactive {
      background-color: rgb(203, 176, 176);
      box-shadow: 
          0 3px 6px rgba(0, 0, 0, 0.3), 
          inset 0 0 0 3px rgba(255, 255, 255, 0.8);  
      font-weight: normal;
      font-size: var(--font-size-title);
      font-family: 'Arial Rounded MT Bold', Arial, sans-serif;  
      font-weight: bold;  
      color: white;  
      text-shadow: 
        -1px -1px 0 #6b4226, 
        1px -1px 0 #6b4226,  
        -1px  1px 0 #6b4226,  
        1px  1px 0 #6b4226,  
        2px  2px 5px rgba(0, 0, 0, 0.5);  
      padding: 5px 10px; 
      border: none;  
      border-radius: 50%;  
      display: inline-block;  
      cursor: pointer;  
    }  */
    
  
  
  
  /* Desktop device */
  @media (min-width: 768px) {  
    .myBookmarks {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      border-radius: 0px;
      margin: 0px 0px;
      justify-content: center;
      background-color: var(--color-orangeA);
      opacity: 1.0;
      /* max-height: 80vh; */
      width: 100vw;
      height: 65vh;
      max-height: 65vh;
      overflow-y: auto;
      padding-right: 0px;  
    } 
  }
  
  /* Mobile device */
  @media (max-width: 767px) {
    .myBookmarks {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      border-radius: 0px;
      margin: 0px 0px;
      justify-content: center;
      background-color: var(--color-orangeA);
      opacity: 1.0;
      /* max-height: 80vh; */
      width: 100vw;
      height: 55vh;
      max-height: 55vh;
      overflow-y: auto;
      padding-right: 0px;  
    } 
  }
  
  .default-sites, .mySites, .sharedSites, .utilitylist { 
    display: flex; 
    flex-wrap: wrap;
    /* gap: 10px; */
    row-gap: 10px;
    column-gap: 10px;
    border-radius: 10px; 
    margin: 0px 0;
    padding: 0;
    justify-content: center;
    background-color: var(--color-orangeA);
    opacity: 1; 
    width: auto;
    height: auto;
    overflow-y: auto;
    padding-right: 0px;
  } 
  
  
  
  .editingTitleInput {
    display: block;
    padding: 0px;
    border: none;
    border-radius: 4px;
    width: 150px;
    resize: none; /* 크기 조절 비활성화 */
    overflow: hidden; /* 스크롤 바 숨김 */
    word-wrap: break-word; /* 긴 단어 줄바꿈 */
    white-space: pre-wrap; /* 입력 중 줄바꿈 처리 */
    font-size: var(font-size-input);
    line-height: 1.0; /* 줄 간격 */
    min-height: calc(1.0em * 1); /* 기본 높이: 4줄 */
    max-height: calc(1.0em * 5); /* 최대 높이: 8줄 (선택 사항) */
    font-weight: plain; 
    font-family: 'Arial', sans-serif; /* Font stack with fallback */
  }
  
  /* 버튼 스타일 */
  .toggle-button {
    display: flex;
    justify-content: center; /* 가로 중앙 정렬 */
    align-items: center; /* 세로 중앙 정렬 */
    opacity: 1.0;
    /* align-self: flex-end; 버튼을 왼쪽으로 배치 */
    position: relative;
    top: 10px; /* 화면 상단에서 10px */
    right: 10px; /* 화면 오른쪽에서 10px */
    margin-bottom: 20px;
    padding: 5px 15px;
    background-color: white;
    color: white;
    border:#333;
    border-radius: 5px;
    cursor: pointer;  
  }
  
  .toggle-button:hover {
    background-color: var(--color-yellow);
  }
  
  .site-card {
    display: flex;  
    position: relative;
    padding: 5px;
    /* background: transparent; */
    background-color: var(--color-white);
    border-radius: 8px; 
    margin: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: all 0.3s ease; 
  }
  
  /* Desktop device */
  @media (min-width: 768px) {  
    .site-card.vertical {
      flex-direction: column; /* 세로 정렬 */
      align-items:start;
      justify-content: space-around;
      width: 130px;
      min-height: 200px;  
      --thumbnail-min-width: auto;
      --thumbnail-min-height: 50%;
      --title-height: 100px;
      --title-width: 100%;
      --title-align-horizontal: left;
      --title-align-vertical: start;
      --thumbnail-align-vertical: center;
      --thumbnail-align-horizontal: center; 
    }
    
    /* 가로 정렬 */
    .site-card.horizontal {
      flex-direction: row; /* 가로 정렬 */ 
      align-items: center;
      justify-content:start;
      width: 450px;
      height: 100%; 
      --thumbnail-min-width: 60%;
      --thumbnail-min-height: 100%;
      --title-height: 100%;
      --title-width: 80%;
      --title-align-horizontal: left;
      --title-align-vertical: center;
      --thumbnail-align-vertical: center;
      --thumbnail-align-horizontal: center; 
    } 
  }
  
  /* Mobile device */
  @media (max-width: 767px) {
    .site-card.vertical {
      flex-direction: column; /* 세로 정렬 */
      align-items:start;
      justify-content: space-around;
      width: 120px;
      min-height: 180px;  
      --thumbnail-min-width: auto;
      --thumbnail-min-height: 50%;
      --title-height: 100px;
      --title-width: 100%;
      --title-align-horizontal: left;
      --title-align-vertical: start;
      --thumbnail-align-vertical: center;
      --thumbnail-align-horizontal: center; 
    }
    
    /* 가로 정렬 */
    .site-card.horizontal {
      flex-direction: row; /* 가로 정렬 */ 
      align-items: center;
      justify-content:start;
      width: 400px;
      height: 100%; 
      --thumbnail-min-width: 60%;
      --thumbnail-min-height: 90%;
      --title-height: 100%;
      --title-width: 80%;
      --title-align-horizontal: left;
      --title-align-vertical: center;
      --thumbnail-align-vertical: center;
      --thumbnail-align-horizontal: center; 
    } 
     
  }
  
  .site-thumbnail {
    min-width: var(--thumbnail-min-width);
    object-fit: cover; /* 썸네일 비율 유지 */
    transition: transform 0.3s;
    /* object-fit: contain; 이미지 비율을 유지하며 크기에 맞추기  */
    display: flex;
    align-items: var(--thumbnail-align-vertical);  /* 수직 중앙 정렬 */
    justify-content: var(--thumbnail-align-horizontal); /* 가로 중앙 정렬 */
    min-height: var(--thumbnail-min-height); /* 부모 컨테이너의 전체 높이를 차지하게 설정 */
    /* border-bottom: 1px solid var(--color-whiter); */ 
  }
  
  .site-title {  
    text-align: left !important;
    display: flex;
    align-items: var(--title-align-vertical); /* 수직 중앙 정렬 */
    justify-content: var(--title-align-horizontal); /* 가로 중앙 정렬 */
    min-height: var(--title-height);  
    width: var(--title-width);
    /* font-family: 'Arial', sans-serif; 폰트 스택 설정 */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: normal; /* 기본 글꼴 굵기 설정 */
    font-size: 0.9rem !important; /* 카드 제목의 폰트 크기 */
    /* white-space: nowrap; 텍스트를 한 줄로 제한 */
    /* overflow: hidden; 넘치는 텍스트 숨기기 */
    /* text-overflow: ellipsis; 생략 표시 추가 */
    flex-wrap: wrap;
  }
  
  
  
  /* cardAction 스타일 */
  /* Desktop */
  @media (min-width: 768px) { 
    .cardAction {
      display: flex;
      justify-content: space-between; /* 버튼들 간격 고르게 분배 */
      align-items: end;
      width: 100%; /* cardAction이 site-card 너비 전체 차지 */
      padding: 0px 4px;
      /* border-top: 1px solid #ccc; 윗부분 구분선 */
      position: absolute;
      bottom: 0px; /* site-card의 하단에 고정 */
      margin-bottom: 0px; 
      margin-top: 0px;
    } 
  }
  
  /* Mobile */
  @media (max-width: 767px) { 
    .cardAction {
      display: flex;
      justify-content: space-between; /* 버튼들 간격 고르게 분배 */
      align-items: end;
      width: 100%; /* cardAction이 site-card 너비 전체 차지 */
      padding: 0px 4px;
      /* border-top: 1px solid #ccc; 윗부분 구분선 */
      position: absolute;
      bottom: 0px; /* site-card의 하단에 고정 */
      margin-bottom: 0px; 
      margin-top: auto;
    } 
  }
  
  .delete-button-home {
    /* position: absolute; */
    /* top: 5px; */
    /* left: 2px; */
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--color-lightergray);
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .delete-button-home:hover {
    color: #ef4df5;
    font-size: 1rem;
    background-color: transparent;
  } 
  
  .super-delete-button {
    /* position: absolute; */
    /* top: 5px; */
    /* left: 2px; */
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--color-blue);
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .super-delete-button:hover {
    color: #4df558;
    font-size: 1rem;
    background-color: transparent;
  } 
  
  .add-site-form {
    display: flex;
    gap: 10px;
    margin: 20px 0;
    justify-content: center;
  }
  
  .add-site-form input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 25px;
    font-size: var(--font-size-input);
  }
   
  .add-site-submit-button {
    display: flex;
    justify-content: center; /* 가로 중앙 정렬 */
    align-items: center; /* 세로 중앙 정렬 */
    background-color: var(--background-color-button);
    padding: 10px 20px;
    border-radius: 10px; 
    opacity: 0.7;  
    border: none;
    cursor: pointer;
    color: white;
    font-size: var(--font-size-button);
    height: 25px;
  } 
  
  .add-site-submit-button:hover {
    background-color: var(--color-whiter);
  }
  
  
  .tooltip {
    position: absolute;
    background-color: var(--color-orange);
    color: var(--color-white);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0.8; 
    white-space: nowrap;
  
    /* 애니메이션 추가 */
    animation: fadeOut 5s forwards;
  }
  
  
  .message_to_new_visitors {
    margin-top: 0px; /* Adjusts spacing below the button */
    font-size: 0.9rem; /* Optional: adjust text size */
    text-align: center; /* Centers text below the button */
    color: #555; /* Optional: adjust text color */
  } 
  
  
   
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.01); /* 반투명 배경 */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 300px;
  }
  
  .popup input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
  }
  
  .popup button {
    margin: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-button, .cancel-button {
    margin: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-button {
    background-color: #d9534f;
    color: white;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: black;
  }
  
  .share-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #c2bbbb;
    font-size: 0.9rem;
    margin-left: auto;  
  } 
  
  .share-button:hover {
    color: #ef4df5;
    font-size: 1rem;
    background-color: transparent;
  }
  
  
  .marketNewsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 10px;
    margin: 30px 0px;
    padding: 0;
    justify-content: center;
    background-color: var(--color-white);
    opacity: 1;
    /* max-height: 50vh; */
    width: 100vw;
    overflow-y: auto;
    padding-right: 0px;
  }
  
  
  /* Overlay background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-white); /* semi-transparent background */
    z-index: 999; /* Behind the overlay-content but above other content */
  }
  
  /* Overlay content box */
  .overlay-content {
    background: rgb(255, 255, 255,0.3);
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }
  
  /* Share options for checkboxes */
  .share-options {
    margin: 10px 0;
  }
  
  .share-options label {
    display: block;
    margin-bottom: 8px;
  }
   
  
  .share-site {
    background: none;
    border: none;
    cursor: pointer;
    color: #c2bbbb;
    font-size: 0.9rem;
    margin-left: auto;  
  } 
  
  .share-site:hover {
    color: #ef4df5;
    font-size: 1rem;
    background-color: transparent;
  } 
  
  
  
  
  .TGabyss {
    position: relative;
    bottom: 0;
    color: white;
    text-align: left;
    padding: 2px 15px;
    font-size: 0.7rem;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 10px;
    border-top: 1px solid #575854;
    opacity: 0.5;
  }