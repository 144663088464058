.Manage {
  padding-top: 90px; /* 상단 패딩 */
  margin: 0 auto; /* 중앙 정렬 */
  /* max-width: 800px; 적절한 폭 설정 */
  display: flex;
  flex-direction: column; /* 세로 정렬 */
  justify-content: center;
} 

.manage-button {
  background-color: #269830;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.visit-count-button {
  /* padding: 10px 20px;
  margin: 10px; */
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #269830;
  color: white;
  padding: 10px 20px;
  margin: 10px;    
  /* width: 600px; */
}

.visit-count-table {
  width: 200px;
  max-width: 300px;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Desktop device */
@media (min-width: 768px) {  
th, td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  font-size: 1.0rem;
  max-width: 400px;
  word-wrap: break-word;
}
}

/* Mobile device */
@media (max-width: 767px) {  
th, td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  font-size: 0.8rem;
  max-width: 200px;
  word-wrap: break-word;
}
}

/* Table selection dropdown */
.table-selection {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.table-selection select {
  font-size: 16px;
  padding: 10px;
  margin: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.table-selection select:hover {
  border-color: #007bff;
}

/* Record list table */
.record-list {
  margin: 0 auto; /* 가로 중앙 정렬 */
  margin-bottom: 20px; 
}



.record-list h3 {
  font-size: 20px;
  color: #34495e;
  margin-bottom: 10px;
}

.record-list table {
  margin: 0 auto; /* 가로 중앙 정렬 */
  width: 100%;
  border-collapse: collapse;
  background-color: #f9fbfd;
  
}

.record-list table tbody {
  display: block; 
  overflow-y: auto;
  width: 100%; /* 테이블 너비 맞추기 */
}

/* Desktop device */
@media (min-width: 768px) {  
  .record-list {
    max-width: 80%;
  }

  .record-list table tbody { 
    max-height: 300px; /* 원하는 높이 제한 */ 
  } 
 
}

/* Mobile device */
@media (max-width: 767px) { 
  .record-list {
    max-width: 85%;
  }

  .record-list table tbody { 
    max-height: 200px; /* 원하는 높이 제한 */ 
  }  
}

.record-list table thead,
.record-list table tbody tr {
  display: table; /* 테이블 구조 유지 */
  table-layout: fixed; /* 열 크기 고정 */
  width: 100%;
}

.record-list table th,
.record-list table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.record-list table th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.record-list table tr:hover {
  background-color: #f1f1f1;
}

.record-list .selected {
  background-color: #ffeb3b;
}

/* Record control buttons */
.record-control {
  text-align: center;
  margin-bottom: 20px;
}

.record-control button {
  padding: 10px 15px;
  margin: 5px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.record-control button:hover {
  background-color: #0056b3;
}

.record-control button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Add/Edit record container */
.add-record,
.edit-record {
  margin: 20px 0;
  padding: 20px;
  background-color: #e9f5ff;
  border: 1px solid #cde4f7;
  border-radius: 10px;
}

.add-record h3,
.edit-record h3 {
  font-size: 18px;
  color: #007bff;
  margin-bottom: 15px;
}

.field-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}

.field-inputs label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.field-inputs input,
.field-inputs select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.field-inputs input:focus,
.field-inputs select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.add-record button,
.edit-record button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-record button:hover,
.edit-record button:hover {
  background-color: #218838;
}

/* Responsive design */
@media (max-width: 768px) {
  .table-selection {
    flex-direction: column;
    align-items: center;
  }

  .field-inputs {
    grid-template-columns: 1fr;
  }
}