
  
  
  @keyframes text-zoom-in {
    0% {
      transform: scale(0.2) translateZ(-200px);
      opacity: 0;
    } 
    100% {
      transform: scale(0.6) translateZ(0);
      opacity: 1;
    }
  }
  
  .radar-text {
    font-size: 2rem;
    color: white;
    animation: text-zoom-in 4s forwards ease-in-out;
    transform-origin: center;
    text-align: center;  
    margin-top: 80px;
  }
  
  @keyframes radar-wave {
    0% {
      transform: scale(0.5);
      opacity: 0.8;
    } 
    100% {
      transform: scale(2);
      opacity: 0.05;
    }
  }
  
  
  .radar-animation {
    width: 100px;
    height: 100px;
    border-radius: 80%;
    background-color: #4caf50;
    animation: radar-wave 5s ease-in-out;
    animation-iteration-count: 2;
    animation-fill-mode: forwards; /* 애니메이션의 마지막 상태를 유지 */
    opacity: 1;
  }
   
  
  @keyframes communication-link {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }
    50% {
      transform: scale(1);
      opacity: 0.7;
    }
    100% {
      transform: scale(2);
      opacity: 0.05;
    }
  }
  
  .radar {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 80%;
    background-color: #4caf50;
    animation: communication-link 5s ease-in-out;
    animation-iteration-count: 2;
    margin-top: 80px;  
    animation-fill-mode: forwards; /* 애니메이션의 마지막 상태를 유지 */
  }
  
  @keyframes radar-sweep {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .beam {
    position: absolute;
    width: 1px;
    height: 50px;
    background-color: #ffeb3b;
    top: 50%;
    left: 50%;
    transform-origin: top left;
    animation: radar-sweep 5s linear;
    animation-iteration-count: 2;
    animation-fill-mode: forwards; /* 애니메이션의 마지막 상태를 유지 */
    opacity: 1;
  }
  
  .main-heading {
    font-size: 2rem;
    color: white;
    margin-top: 60px;
    margin-bottom: 100px; /* 이 h1 태그 아래에 30px의 수직 간격 추가 */
  }
  
  .TGabyss {
    position: fixed;
    bottom: 0;
    /* background-color: #282c34; */
    color: white;
    /* justify-content: center; 수평 가운데 정렬 */
    text-align: center;
    align-items: center;
    padding: 2px 15px;
    font-size: 0.7rem;
    line-height: 1.6;
    margin-bottom: 10px;
    border-top: 1px solid #575854;
    opacity: 0.5;
  }

  
  .Copyright {
    background-color: #282c34; /* 어두운 배경 */
    color: white; /* 흰색 텍스트 */
    text-align: center; /* 중앙 정렬 */
    padding: 2px 20px; /* 상하 10px, 좌우 20px 패딩 */
    font-size: 0.9rem; /* 조금 작은 폰트 크기 */
    line-height: 1.6; /* 읽기 좋은 간격 */
    margin-top: 0px;
    font-size: 0.7rem; /* 조금 더 작은 폰트 크기 */
    padding-bottom: 20px; /* 하단 여백 추가 */
    /* border-bottom: 1px solid #4caf50; 하단 구분선 */
  }
  
  /* 슬라이딩 메뉴 */
  /* 슬라이딩 메뉴에 마우스 포커스 시 효과 추가 */
  
  @keyframes menucontainerAnim {
    0% {
      transform: scale(1.0);
      opacity: 0;
    } 
    10% {
      transform: scale(1.0);
      opacity: 0.4;
    }
    100% {
      transform: scale(1.0);
      opacity: 0.8;
    }
  }
  
  .MenuContainer {
    width: 100vw; /* 화면 너비의 40% */
    height: 30vh; /* 화면 높이의 30% */
    background-color: transparent;
    color: white;  
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;  
    position: relative;
    /* padding: 10px; */
    margin-top: -160px;  
    transition: transform 0.3s;
    animation: menucontainerAnim linear; /* 5s linear가 원래 값이었음. */
    animation-iteration-count: 1;
    animation-fill-mode: forwards; /* 애니메이션의 마지막 상태를 유지 */
  }
  
   
  .MenuContainer:hover {
    background-color: transparent; /* 마우스 포커스 시 색상 변경 */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* 포커스 시 그림자 효과 */
  }
  
  /* 메뉴 항목 스타일 */
  .MenuItem {
    width: 100vw; /* 화면 너비의 40% */
    height: 30vh; /* 화면 높이의 30% */
    font-size: 1.4rem;
    font-weight: bold;
    color: white;
    padding: 20px;
    border-radius: 0px;
    background-size: cover; /* 이미지 크기 맞춤 */
    background-position: center; /* 이미지 중앙 정렬 */
    position: relative;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8); /* 텍스트 그림자 효과 */
  }
  
  .Tooltip {
    display: flex; /* Flexbox 활성화 */
    align-items: center; /* 수직 중앙 정렬 */
    width: 90vw; /* 화면 너비의 40% */
    height: 15vh; /* 화면 높이의 30% */
    /* background-color: #4caf50;  */
    justify-content: center; /* 수평 중앙 정렬 */
    text-align: center; /* 텍스트 수평 중앙 정렬 */
    position: absolute;
    top: 55px;
    left: 50%;
    transform: translateX(-50%);
    /* background-color: rgba(0, 0, 0, 0.8); */
    color: rgb(243, 244, 241);
    /* padding: 5px 10px; */
    border-radius: 5px;
    font-size: 1.1rem; 
    white-space: wrap;
    opacity: 0.9;
    z-index: 10; 
    word-wrap: break-word; /* 긴 단어가 잘리도록 */ 
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* 가독성 좋은 글꼴 */
  } 
  
  @keyframes backgroundScale {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
  
  .background-animation {
    animation: backgroundScale 3s ease-in-out forwards;
  }

