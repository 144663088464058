/* Global styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
  text-align: center;
}

/* Container for the game */
.HeadsUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100%; */
  /* margin-top: 5%; */
  margin-top: 0vh;
  /* margin-bottom: 8vh; */
}
 
.okbutton { 
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} 

.okbutton button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 30vw;
}

.okbutton button:hover {
  background-color: #0056b3;
}

/* Start screen */
.start-screen {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.start-screen h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.game-over-screen{ 
  margin-top: 0px;
}

.start-screen button {
  padding: 5px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.start-screen button:hover {
  background-color: #218838;
}
.volume-control-div {
  margin-top: 40px;
}

/* Game screen */
.game-screen {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  margin-top: 0px; 
}

.game-screen h1 {
  font-size: 48px;
  margin: 20px 60px;
}

.game-screen h2 {
  font-size: 36px;
  margin: 20px 0;
}

.game-controls {
  display: flex; /* Flexbox를 활성화 */
  justify-content: space-between; /* 버튼 간격 조정 */
  align-items: center; /* 버튼 수직 중앙 정렬 */
  gap: 10px; /* 버튼 간 간격 */
  margin-top: 10px;
}

.game-controls button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ffc107;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  /* width: 80px;  */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 버튼 그림자 */
}

.game-controls button:hover {
  background-color: #50da35d8;
}

/* Score display */
.score {
  margin-top: 0px;
  font-size: 24px;
  font-weight: bold;
}

/* Reset button */
.reset-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #c82333;
}

.game-screen .timer {
  font-size: 20px;
  font-weight: bold;
  color: red;
  /* margin-top: 10px; */
}

.game-screen .score {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.game-instructions {
  width: 80%; /* 컨테이너의 너비 설정 */
  max-width: 600px; /* 최대 너비 */
  margin: 20px auto; /* 화면 중앙 정렬 */
  padding: 20px; /* 내부 여백 */
  background-color: #f9f9f9; /* 연한 회색 배경 */
  border-radius: 10px; /* 둥근 모서리 */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 */
  font-family: 'Arial', sans-serif; /* 글꼴 설정 */
  text-align: center; /* 텍스트 중앙 정렬 */
  color: #333; /* 텍스트 색상 */ 
}

.game-instructions h2 {
  font-size: 1.5rem; /* 제목 크기 */
  color: #4E8CA1; /* 제목 색상 */
  margin-bottom: 15px; /* 제목 아래 간격 */
}

.game-instructions p {
  font-size: 1rem; /* 본문 글씨 크기 */
  line-height: 1.6; /* 줄 간격 */
  margin-bottom: 15px; /* 본문 아래 간격 */
  text-align: justify; /* 텍스트 양쪽 정렬 */
}

.game-instructions img {
  display: block; /* 이미지를 블록 요소로 설정 */
  margin: 20px auto; /* 중앙 정렬 */
  max-width: 100%; /* 이미지가 컨테이너를 벗어나지 않도록 설정 */
  height: auto; /* 비율 유지 */
  border-radius: 8px; /* 둥근 모서리 */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 */
}

.game-instructions button {
  display: inline-block; /* 버튼을 인라인 블록으로 설정 */
  padding: 10px 20px; /* 버튼 내부 여백 */
  font-size: 1rem; /* 버튼 글씨 크기 */
  font-weight: bold; /* 글씨 두께 */
  color: white; /* 글씨 색상 */
  background-color: #6A9FB5; /* 버튼 배경색 */
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 둥근 버튼 */
  cursor: pointer; /* 마우스 커서 스타일 */
  transition: all 0.3s ease; /* 부드러운 애니메이션 효과 */
  margin-top: 15px; /* 버튼 위쪽 간격 */
}

.game-instructions button:hover {
  background-color: #5A8CA5; /* 호버 시 배경색 변경 */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* 호버 시 그림자 강조 */
}

.game-instructions button:active {
  background-color: #4E8CA1; /* 클릭 시 색상 변경 */
  transform: translateY(2px); /* 클릭 시 버튼 눌리는 효과 */
}


/* Record list table */
.record-list {
margin: 0 auto; /* 가로 중앙 정렬 */ 
margin-bottom: 20px; 
}



.record-list h3 {
font-size: 20px;
color: #34495e;
margin-bottom: 10px;
margin-top: 20px;
}

.record-list table {
margin: 0 auto; /* 가로 중앙 정렬 */
width: 100%;
border-collapse: collapse;
background-color: #f9fbfd;

}

.record-list table tbody {
display: block; 
overflow-y: auto;
width: 100%; /* 테이블 너비 맞추기 */ 
}

/* Desktop device */
@media (min-width: 768px) {  
.record-list {
  max-width: 80%;
}

.record-list table tbody { 
  max-height: 300px; /* 원하는 높이 제한 */ 
} 

}

/* Mobile device */
@media (max-width: 767px) { 
.record-list {
  max-width: 95%;
}

.record-list table tbody { 
  max-height: 300px; /* 원하는 높이 제한 */ 
}  
}

.record-list table thead,
.record-list table tbody tr {
display: table; /* 테이블 구조 유지 */
table-layout: fixed; /* 열 크기 고정 */
width: 100%;
}

.record-list table th,
.record-list table td {
padding: 10px;
text-align: left;
border: 1px solid #ddd;
}

.record-list table th {
background-color: #007bff;
color: #fff;
font-weight: bold;
}


.word-details {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  max-width: 600px;
  margin: 10px auto;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  justify-items:left;
  max-height: 350px; /* Set the maximum height for the container */
  height: 320px;
  overflow-y: auto; /* Enable vertical scroll bar when content overflows */
}

.word {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.part-of-speech {
  font-size: 1.0rem;
  color: #007bff;
  margin-bottom: 10px;
}

.meaning {
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center;    /* 수직 중앙 정렬 */
  text-align: center;     /* 텍스트 정렬 */

  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;    
}

.meaning-summary {
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center;    /* 수직 중앙 정렬 */
  text-align: center;     /* 텍스트 정렬 */

  font-size: 1.0rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;    
}

.example1,
.synonym {
  font-size: 1rem;
  color: #201c1c;
  margin-bottom: 10px;
}

.example1 strong,
.synonym strong {
  color: #007bff;
}

.word-style:hover {
  background-color: var(--color-yellow);
  cursor: pointer; /* 마우스 포인터를 손가락 모양으로 변경 */
} 

.word-details .example1 { 
  text-align: left;
} 

.word-details .example1:hover {
  background-color: var(--color-yellow);
  cursor: pointer; /* 마우스 포인터를 손가락 모양으로 변경 */ 
} 

.word-details .explanation_en{
  background-color: var(--color-white);
  cursor: pointer; /* 마우스 포인터를 손가락 모양으로 변경 */
  text-align: left;
} 

.word-details .explanation_en:hover {
  background-color: var(--color-yellow);
  cursor: pointer; /* 마우스 포인터를 손가락 모양으로 변경 */
} 

.word-style {
  font-family: 'Arial, sans-serif'; /* 폰트 설정 */
  font-size: 1.5rem; /* 폰트 크기 설정 */
  color: #007bff; /* 색상 설정 (파란색) */
  font-weight: bold; /* 굵은 글씨 */ 
}

.word-style-summary {
  font-family: 'Arial, sans-serif'; /* 폰트 설정 */
  font-size: 1.5rem; /* 폰트 크기 설정 */
  color: #007bff; /* 색상 설정 (파란색) */
  font-weight: bold; /* 굵은 글씨 */ 

  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center;    /* 수직 중앙 정렬 */
  text-align: center;     /* 텍스트 정렬 */
 
  margin-bottom: 5px;    
}


/* 오디오 컨트롤 컨테이너 */
.music-player {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border: 2px solid #ccc;
  border-radius: 10px; 
  background-color: #f9f9f9; 
}

/* 버튼 스타일 */
.music-player button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px; 
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.music-player button:hover {
  background-color: #45a049;
}

.music-player button:active {
  background-color: #3e8e41;
}

/* 슬라이더 컨트롤 */
.music-player .volume-control {
  display: flex;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  margin: 0 20px;
}

.music-player .volume-control label {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
} 

.music-player .volume-control input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.music-player .volume-control input[type="range"]::-webkit-slider-thumb:hover {
  background: #45a049;
}

.music-player .volume-control span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
}


.startGameButton{
  margin-top: 20px;
}

button:hover {
  background-color: darkorange;
  color: white;
}
