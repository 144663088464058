/* 전체 페이지 스타일 */
.ScanPage {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
  padding: 20px;
}

/* 헤더 스타일 */
.ScanPage header h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #007bff;
}

/* 스캔 영역 스타일 */
.scanning-area {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  border: 2px solid #007bff;
  border-radius: 8px;
  overflow: hidden;
}

.scanning-area canvas,
.scanning-area video {
  display: block;
  width: 100%;
  height: auto;
}

/* 버튼 스타일 */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}

button:hover {
  background-color: #0056b3;
}

/* 스캔 미리보기 스타일 */
.scan-preview {
  margin: 20px auto;
  max-width: 600px;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
}

.scan-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* 업로드된 파일 목록 스타일 */
.UploadedFiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.UploadedFiles .file-card {
  width: 150px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.UploadedFiles .file-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.UploadedFiles .file-card p {
  font-size: 0.9em;
  margin-top: 8px;
  color: #555;
}

/* 스캔된 이미지 목록 스타일 */
.ScannedImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.ScannedImages .scanned-card {
  width: 150px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ScannedImages .scanned-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.ScannedImages .scanned-card button {
  margin-top: 10px;
  background-color: #28a745;
}

.ScannedImages .scanned-card button:hover {
  background-color: #218838;
}

/* 로딩 표시 스타일 */
.loading {
  font-size: 1.2em;
  color: #007bff;
  margin-top: 20px;
}
