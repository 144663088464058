/* HomeAdv.css */

/* 광고 전체 컨테이너 */
.home-adv-container {
    text-align: center;
    padding: 0px;
    background-color: #f9f9f9; /* 배경 색상 */
    /* border: 1px solid #ddd;  */
    /* border-radius: 8px;  */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  */
    margin: 20px auto; /* 상하 여백 및 가운데 정렬 */
    max-width: 600px; /* 최대 너비 */
  }
  
  /* 광고 제목 스타일 */
  .home-adv-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333; /* 제목 글씨 색상 */
    margin-bottom: 10px; /* 아래 여백 */
  }
  
  /* 광고 내용 텍스트 스타일 */
  .home-adv-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px; /* 아래 여백 */
  }
  
  /* 광고 이미지 스타일 */
  .home-adv-image {
    max-width: 100%; /* 이미지 너비 제한 */
    height: auto; /* 비율 유지 */
    border-radius: 4px; /* 이미지 둥근 모서리 */
    margin-bottom: 15px; /* 아래 여백 */
  }
  
  /* 광고 링크 버튼 스타일 */
  .home-adv-link {
    display: inline-block;
    text-decoration: none;
    /* color: #007bff;   */
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    /* border: 2px solid #007bff;   */
    /* border-radius: 4px;   */
    transition: all 0.3s ease-in-out; /* 부드러운 전환 효과 */
  } 
  
  /* 반응형 디자인: 모바일 화면에서 간격 조정 */
  @media (max-width: 768px) {
    .home-adv-container {
      padding: 15px;
      margin: 10px;
    }
  
    .home-adv-title {
      font-size: 1.25rem;
    }
  
    .home-adv-description {
      font-size: 0.9rem;
    }
  }
  